//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Actions
import { logoutUser } from "../../actions/userManagement"

//Icons
import { MdMenu, MdExitToApp, MdPerson, MdPeople } from "react-icons/md"
import { AiFillTags } from "react-icons/ai"
import { IoMdImages, IoIosHelpBuoy } from "react-icons/io"

//Redux
const mapDispatchToProps = {
  logoutUser,
}

class Nav extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { userRole } = this.props

    return (
      <div className="dropdown dropdown-app-menu">
        <button
          className="btn btn-app-menu"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <MdMenu size={23} />
        </button>
        <div
          className="dropdown-menu dropdown-menu-right fade black-3 border-1-blue-1"
          aria-labelledby="dropdownMenuButton"
        >
          <Link
            className="dropdown-item d-flex flex-row"
            to="/app/publisher/"
            title="Publisher"
            // style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
          >
            <span class="pr-3 w-100">Publisher</span>
            <div style={{ marginLeft: "auto" }}>
              <IoMdImages
                size={20}
                className="black-2"
                style={{ marginTop: -3 }}
              />
            </div>
          </Link>
          {/* {userRole && userRole === "admin" ? (
            <Link
              className="dropdown-item d-flex flex-row"
              to="/app/tags/"
              title="Template tags"
              // style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
            >
              <span class="pr-3 w-100">Template tags</span>
              <div style={{ marginLeft: "auto" }}>
                <AiFillTags
                  size={20}
                  className="black-2"
                  style={{ marginTop: -3 }}
                />
              </div>
            </Link>
          ) : null} */}
          <div class="dropdown-divider"></div>
          <Link
            className="dropdown-item d-flex flex-row"
            to="/app/profile/"
            // style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
          >
            <span class="pr-3 w-100">Profile</span>
            <div style={{ marginLeft: "auto" }}>
              <MdPerson
                size={20}
                className="black-2"
                style={{ marginTop: -3 }}
              />
            </div>
          </Link>
          {userRole && userRole === "admin" ? (
            <Link
              className="dropdown-item d-flex flex-row"
              to="/app/users/"
              // style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
            >
              <span class="pr-3 w-100">Account users</span>
              <div style={{ marginLeft: "auto" }}>
                <MdPeople
                  size={20}
                  className="black-2"
                  style={{ marginTop: -3 }}
                />
              </div>
            </Link>
          ) : null}
          <div class="dropdown-divider"></div>
          <a
            className="dropdown-item d-flex flex-row"
            href={`https://${process.env.HOSTNAME_SUPPORT}`}
            target="_blank"
            rel="noopener noreferrer"
            // style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
          >
            <span class="pr-3 w-100">Help &amp; support</span>
            <div style={{ marginLeft: "auto" }}>
              <IoIosHelpBuoy
                size={20}
                className="black-2"
                style={{ marginTop: -3 }}
              />
            </div>
          </a>
          <div class="dropdown-divider"></div>

          <button
            className="dropdown-item d-flex flex-row"
            // style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
            type="button"
            onClick={this.props.logoutUser}
          >
            <span class="pr-3 w-100">Sign out</span>
            <div style={{ marginLeft: "auto" }}>
              <MdExitToApp
                size={20}
                className="black-2"
                style={{ marginTop: -3 }}
              />
            </div>
          </button>

          {/* <Link className="dropdown-item d-flex flex-row" to="/">

        </Link> */}
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(Nav)
