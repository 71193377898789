//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

//Components
import NavAppDropdownMenu from "./navAppDropdownMenu"

//Assets
import Logo from "../../images/svg/storylava-logo-04.svg"

export default class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  render() {
    const { userRole } = this.props
    return (
      <nav class="navbar navbar-expand navbar-light bg-light">
        <div class="d-flex flex-row w-100">
          <Link
            class="navbar-brand"
            activeClassName="navbar-brand"
            to="/app/publisher/"
          >
            <Logo />
          </Link>
          <div class="ml-auto">
            <NavAppDropdownMenu userRole={userRole} />
          </div>
          {/* <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="navbar-toggler-icon" />
          </button> */}
        </div>
      </nav>
    )
  }
}
